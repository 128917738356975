@import './standard.css';
@import '../node_modules/spectre.css/dist/spectre-exp.min.css';

html {
  --transition-timing: cubic-bezier(0.42, 0, 0.6, 1.05);
}

html,
body,
main {
  height: 100%;
  width: 100%;
}

body {
  padding: 0;
}

main {
  position: relative;
  overflow: hidden;
}

main,
main section,
main section a:visited,
.chip {
  transition-property: background-color;
  transition-timing-function: var(--transition-timing);
  transition-duration: 1s;
}

h1 {
  font-size: 1.7rem;
  margin-bottom: 0.3rem;
  text-align: unset;
}
h2 {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1rem;
  margin-bottom: 1.4rem;
  padding-top: unset;
}

h3 {
  font-size: 1.2rem;
}

h4 {
  font-size: 1rem;
}

p {
  margin-bottom: 0.2rem;
}

i {
  width: 1rem !important;
}

.container {
  padding: 0.5rem 1rem 0 1rem;
  width: auto;
}

section.background {
  height: 100%;
}

section.background h2 {
  margin-bottom: 0;
}

section.background h3 {
  padding-top: 0.5rem;
}

section.background div.container {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
}

section.background header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.avatar.avatar-xl {
  height: 4rem;
  width: 4rem;
  flex-shrink: 0;
}

section.background blockquote {
  margin-bottom: 1.5rem;
  line-height: 1rem;
  margin-block-end: 0;
  margin-inline-end: 0;
}

section.background div.container > div.animated-load {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
}

section.draggable {
  position: absolute;
  border-top-right-radius: 64px;
  bottom: -300%;
  transform: translateY(100%);
  animation: 500ms var(--transition-timing) 1500ms stacks-appear forwards;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}

@keyframes stacks-appear {
  to {
    transform: translateY(0);
  }
}

section.draggable div.container {
  max-height: 100%;
}

section.draggable.transition {
  transition-property: all;
  transition-timing-function: var(--transition-timing);
  transition-duration: 1s;
}

section.draggable article + article {
  padding-top: 1rem;
}

div.articles {
  max-height: calc(100% - 3rem);
  overflow-x: hidden;
  overflow-y: auto;
}

.timeline .timeline-item .timeline-icon.icon-lg {
  background: transparent;
  border-width: 0.11rem;
  border-style: solid;
  border-radius: 50%;
  margin-top: 0.2rem;
  height: 0.8rem;
  width: 0.8rem;
}

.timeline .timeline-item::before {
  left: 5px;
  top: 1.2rem;
  width: 1px;
}

p.tile-subtitle.description {
  padding-top: 0.1rem;
}

h4.tile-title {
  margin-top: -2px;
}

.chip {
  height: 1.4rem;
  max-width: unset;
}

.tile-title,
.tile.tile-centered .tile-subtitle {
  white-space: unset !important;
}
.tile-subtitle.description {
  white-space: pre-line;
}

i.contact {
  margin-right: 1rem;
}
